import {
  ChargeBasedOn,
  ContextType,
  ContractRecommendationStrengthType,
  DataSource,
  FeeType,
  GroupType,
  IllustrationType,
  MorningstarDescriptionType,
  ProductOverlayStrictnessLevel,
  ProposalCaseStatusType,
  ProposalStatusType,
  RiderSubType,
  RiderType,
  RiskCalculationStrategy,
  RiskCalculationType,
  SelectionRuleType,
  SurrenderChargeType,
  TransactionApprovalStatusType
} from './datamart.enums';

export class AllowedRelationshipDto {
  relatedRoleId?: number;
  id?: number;
  description?: string | null = null;

  constructor(init?: Partial<AllowedRelationshipDto>) {
    Object.assign(this, init);
  }
}

export class AllowableProductDto {
  cusip?: string | null = null;
  productCode?: string | null = null;

  constructor(init?: Partial<AllowableProductDto>) {
    Object.assign(this, init);
  }
}

export class ArrangementSubTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ArrangementSubTypeDto>) {
    Object.assign(this, init);
  }
}

export class ArrangementTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ArrangementTypeDto>) {
    Object.assign(this, init);
  }
}

export class BasicSearchCriteriaDto {
  search?: string | null = null;

  constructor(init?: Partial<BasicSearchCriteriaDto>) {
    Object.assign(this, init);
  }
}

export class BenefitTypeDto {
  riderSubType: RiderSubType = RiderSubType.Unknown;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<BenefitTypeDto>) {
    Object.assign(this, init);
  }
}

export class BenefitTypesSearchCriteriaDto {
  riderSubTypeIds?: number[] | null = [];

  constructor(init?: Partial<BenefitTypesSearchCriteriaDto>) {
    Object.assign(this, init);
  }
}

export class CarrierDto {
  naicCode?: string | null = null;
  dtccMemberCode?: string | null = null;
  carrierGroupCode?: string | null = null;
  name?: string | null = null;
  properties?: CarrierPropertyDto[] | null = null;
  contactInformation?: ContactInformationDto | null = null;
  insuranceCompanyId?: string | null = null;
  dataSource: DataSource = DataSource.Ppfa;

  constructor(init?: Partial<CarrierDto>) {
    Object.assign(this, init);
  }
}

export class CarrierPropertyDto {
  propertyName?: string | null = null;
  propertyValue?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<CarrierPropertyDto>) {
    Object.assign(this, init);
  }
}

export class CarrierSearchCriteriaDto {
  dataSources?: DataSource[] | null = [];

  constructor(init?: Partial<CarrierSearchCriteriaDto>) {
    Object.assign(this, init);
  }
}

export class CaseSummaryDto {
  totalCount: number = 0;
  statusBreakdown?: Record<ProposalCaseStatusType, number> | null = null;
  imoStatusBreakdown?: Record<ProposalCaseStatusType, number> | null = null;
  oidStatusBreakdown?: Record<ProposalCaseStatusType, number> | null = null;

  constructor(init?: Partial<CaseSummaryDto>) {
    Object.assign(this, init);
  }
}

export class ContractAgentDto {
  id: string = '';
  loadSetId: string = '';
  contractNumber?: string | null = null;
  userId?: string | null = null;
  dtccMemberCode?: string | null = null;
  createDate?: Date | null = new Date(0);
  createUser?: string | null = null;
  updateDate?: Date | null = new Date(0);
  updatedUser?: string | null = null;
  contractRecordId: string = '';

  constructor(init?: Partial<ContractAgentDto>) {
    Object.assign(this, init);
  }
}

export class ComparisonPdfCreateDto {
  annuityProducts: AnnuityProduct[] = [];
}

export class AnnuityProduct {
  order: number = 0;
  cusip: string = '';
  productCode: string = '';
  riderKeys: string[] = [];
  subaccountKeys: string[] = [];
}

export class ProductComparePdfAttachmentDto {
  fileName: string = '';
  content: string = '';
  contentType: string = '';
}

export class ContractAssetDto {
  id: string = '';
  loadSetId: string = '';
  contractNumber?: string | null = null;
  fundCusip?: string | null = null;
  fundId?: string | null = null;
  fundName?: string | null = null;
  fundType?: string | null = null;
  fundPercentage: number = 0;
  fees: number = 0;
  contractValue: number = 0;
  subaccountProductCode?: string | null = null;
  subaccountMorningstarId?: string | null = null;
  subFundId?: string | null = null;
  targetPercentage?: number | null = null;
  driftPercentage?: number | null = null;
  createDate?: Date | null = new Date(0);
  createUser?: string | null = null;
  updateDate?: Date | null = new Date(0);
  updatedUser?: string | null = null;
  contractAssetRecordId: string = '';
  contractRecordId: string = '';

  constructor(init?: Partial<ContractAssetDto>) {
    Object.assign(this, init);
  }
}

export class ContractAssetGuaranteedRateDto {
  id: string = '';
  loadSetId: string = '';
  contractNumber?: string | null = null;
  fundCusip?: string | null = null;
  fundId?: string | null = null;
  rateTypeCodeId: number = 0;
  rate: number = 0;
  createDate?: Date | null = new Date(0);
  createUser?: string | null = null;
  updateDate?: Date | null = new Date(0);
  updatedUser?: string | null = null;
  contractRecordId: string = '';
  contractAssetRateType?: ContractAssetRateTypeDto | null = null;

  constructor(init?: Partial<ContractAssetGuaranteedRateDto>) {
    Object.assign(this, init);
  }
}

export class ContractAssetRateTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ContractAssetRateTypeDto>) {
    Object.assign(this, init);
  }
}

export class ContractAveragesDto {
  averageContractValuationAmount: number = 0;
  dailyAverages?: ContractDailyAverageDto[] | null = null;

  constructor(init?: Partial<ContractAveragesDto>) {
    Object.assign(this, init);
  }
}

export class ContractDailyAverageDto {
  averageContractValuationAmount: number = 0;
  calendarDate?: Date | null = new Date(0);

  constructor(init?: Partial<ContractDailyAverageDto>) {
    Object.assign(this, init);
  }
}

export class ContractDateDto {
  id: string = '';
  loadSetId: string = '';
  contractNumber?: string | null = null;
  contractDateTime?: Date | null = new Date(0);
  dateCodeId: number = 0;
  createUser?: string | null = null;
  createDate?: Date | null = new Date(0);
  updatedUser?: string | null = null;
  updateDate?: Date | null = new Date(0);
  contractRecordId: string = '';
  contractDateType?: ContractDateTypeDto | null = null;

  constructor(init?: Partial<ContractDateDto>) {
    Object.assign(this, init);
  }
}

export class ContractDateTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ContractDateTypeDto>) {
    Object.assign(this, init);
  }
}

export class ContractDefinitionDto {
  id: string = '';
  loadSetId: string = '';
  contractNumber?: string | null = null;
  cusip?: string | null = null;
  productCode?: string | null = null;
  productName?: string | null = null;
  productTypeCode?: string | null = null;
  productType?: string | null = null;
  carrierId?: string | null = null;
  carrierName?: string | null = null;
  contractStateCode?: string | null = null;
  taxQualification?: string | null = null;
  contractStatus?: string | null = null;
  commissionOption?: string | null = null;
  commissionScheduleIdentifier?: string | null = null;
  contractPartyRoleQualifier?: string | null = null;
  ownershipType?: string | null = null;
  asOfDate?: Date | null = new Date(0);
  createDate?: Date | null = new Date(0);
  createUser?: string | null = null;
  updateDate?: Date | null = new Date(0);
  updateUser?: string | null = null;
  contractRecordId: string = '';
  firmId?: string | null = null;
  morningStarPolicyId?: string | null = null;
  dtccCarrierNumber?: string | null = null;
  isBackbook?: boolean | null = null;
  issueDate?: Date | null = new Date(0);
  maturityDate?: Date | null = null;
  deathBenefit?: number | null = null;
  premiumsPaid?: number | null = null;
  contractValue?: number | null = null;
  ownerName?: string | null = null;
  ownerAnnuitantOrInsuredName?: string | null = null;
  contractAgents?: ContractAgentDto[] | null = null;
  contractAssetGuaranteedRates?: ContractAssetGuaranteedRateDto[] | null = null;
  contractAssets?: ContractAssetDto[] | null = null;
  contractDates?: ContractDateDto[] | null = null;
  contractEvents?: ContractEventDto[] | null = null;
  contractFeatures?: ContractFeatureDto[] | null = null;
  contractParties?: ContractPartyDto[] | null = null;
  contractValuations?: ContractValuationDto[] | null = null;
  contractTransactions?: ContractTransactionDto[] | null = null;
  contractRisk?: ContractRiskDto | null = null;
  contractAnnuitants?: ContractPartyDto[] | null = null;
  contractOwners?: ContractPartyDto[] | null = null;

  constructor(init?: Partial<ContractDefinitionDto>) {
    Object.assign(this, init);
  }
}

export class ContractDto {
  contractNumber?: string | null = null;
  platformContractId?: string | null = null;
  carrierCode?: string | null = null;

  constructor(init?: Partial<ContractDto>) {
    Object.assign(this, init);
  }
}

export class ContractDefinitionSearchCriteriaDto extends BasicSearchCriteriaDto {
  isActiveOnly: boolean = false;
  firmDtccMemberCode?: string | null = null;
  contracts?: ContractDto[] | null = [];

  constructor(init?: Partial<ContractDefinitionSearchCriteriaDto>) {
    super(init);
    Object.assign(this, init);
  }
}

export class ContractDefinitionSummarySearchCriteriaDto extends BasicSearchCriteriaDto {
  isActiveOnly: boolean = false;
  firmDtccMemberCode?: string | null = null;
  contracts?: ContractDto[] | null = [];

  constructor(init?: Partial<ContractDefinitionSummarySearchCriteriaDto>) {
    super(init);
    Object.assign(this, init);
  }
}

export class ContractDefinitionSummaryDto {
  contractStatus?: string | null = null;
  totalContractValuationAmount: number = 0;
  contractCount: number = 0;
  contractAverages?: ContractAveragesDto | null = null;

  constructor(init?: Partial<ContractDefinitionSummaryDto>) {
    Object.assign(this, init);
  }
}

export class ContractEventDto {
  id: string = '';
  loadSetId: string = '';
  contractNumber?: string | null = null;
  totalAmount?: number | null = null;
  periodCodeId?: number | null = null;
  typeCodeId?: number | null = null;
  isNet?: boolean | null = null;
  nextEventDate1?: Date | null = null;
  nextEventDate2?: Date | null = null;
  nextEventDate3?: Date | null = null;
  nextEventDate4?: Date | null = null;
  nextEventDate5?: Date | null = null;
  createUser?: string | null = null;
  createDate?: Date | null = new Date(0);
  updatedUser?: string | null = null;
  updateDate?: Date | null = new Date(0);
  contractRecordId: string = '';
  contractEventPeriodType?: ContractEventPeriodTypeDto | null = null;
  contractEventType?: ContractEventTypeDto | null = null;

  constructor(init?: Partial<ContractEventDto>) {
    Object.assign(this, init);
  }
}

export class ContractEventPeriodTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ContractEventPeriodTypeDto>) {
    Object.assign(this, init);
  }
}

export class ContractEventTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ContractEventTypeDto>) {
    Object.assign(this, init);
  }
}

export class ContractFeatureDto {
  id: string = '';
  loadSetId: string = '';
  contractNumber?: string | null = null;
  featureName?: string | null = null;
  feeCode1?: number | null = null;
  feePercentage1?: number | null = null;
  feeCode2?: number | null = null;
  feePercentage2?: number | null = null;
  totalFeePercentage?: number | null = null;
  featureTypeCode1?: number | null = null;
  featureTypeCode2?: number | null = null;
  featureTypeCode3?: number | null = null;
  featureTypeCode4?: number | null = null;
  featureTypeCode5?: number | null = null;
  featureTypeCode6?: number | null = null;
  createDate?: Date | null = new Date(0);
  createUser?: string | null = null;
  updateDate?: Date | null = new Date(0);
  updatedUser?: string | null = null;
  livesType?: string | null = null;
  featureSubTypeCode1?: number | null = null;
  contractServiceFeatureRecordId: string = '';
  contractRecordId: string = '';
  feeType1?: ContractFeeTypeDto | null = null;
  feeType2?: ContractFeeTypeDto | null = null;
  featureType1?: ContractFeatureTypeDto | null = null;
  featureType2?: ContractFeatureTypeDto | null = null;
  featureType3?: ContractFeatureTypeDto | null = null;
  featureType4?: ContractFeatureTypeDto | null = null;
  featureType5?: ContractFeatureTypeDto | null = null;
  featureType6?: ContractFeatureTypeDto | null = null;

  constructor(init?: Partial<ContractFeatureDto>) {
    Object.assign(this, init);
  }
}

export class ContractFeeTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ContractFeeTypeDto>) {
    Object.assign(this, init);
  }
}

export class ContractFeatureTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ContractFeatureTypeDto>) {
    Object.assign(this, init);
  }
}

export class ContactInformationDto {
  addressLine1?: string | null = null;
  addressLine2?: string | null = null;
  addressLine3?: string | null = null;
  addressLine4?: string | null = null;
  phone?: string | null = null;
  fax?: string | null = null;
  emailAddress?: string | null = null;
  homepage?: string | null = null;
  city?: string | null = null;
  state?: string | null = null;
  zipCode?: string | null = null;

  constructor(init?: Partial<ContactInformationDto>) {
    Object.assign(this, init);
  }
}

export class ContractPartyDto {
  id: string = '';
  loadSetId: string = '';
  contractNumber?: string | null = null;
  nonNaturalEntityName?: string | null = null;
  lastName?: string | null = null;
  firstName?: string | null = null;
  middleName?: string | null = null;
  dateofBirth?: Date | null = null;
  nonNaturalEntityDate?: Date | null = null;
  contractPartyTypeId: number = 0;
  addressLine1?: string | null = null;
  addressLine2?: string | null = null;
  city?: string | null = null;
  state?: string | null = null;
  postalCode?: string | null = null;
  sameAsAnnuitant?: boolean | null = null;
  proceedsPercentage?: number | null = null;
  proceedsAmount?: number | null = null;
  contractPartyRoleQualitifer?: string | null = null;
  lastUpdateDate?: Date | null = null;
  createDate?: Date | null = new Date(0);
  createUser?: string | null = null;
  updateDate?: Date | null = new Date(0);
  updatedUser?: string | null = null;
  contractRecordId: string = '';
  contractPartyType?: ContractPartyTypeDto | null = null;

  constructor(init?: Partial<ContractPartyDto>) {
    Object.assign(this, init);
  }
}

export class ContractPartyTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ContractPartyTypeDto>) {
    Object.assign(this, init);
  }
}

export class ContractRecommendationDto {
  id: string = '';
  contractNumber?: string | null = null;
  createdUtc?: Date | null = null;
  envestnetContractId: string = '';
  created?: Date | null = null;
  updated?: Date | null = null;
  category?: string | null = null;
  type?: string | null = null;
  subType?: string | null = null;
  objectType?: string | null = null;
  objectValue?: string | null = null;
  objectId?: string | null = null;
  description?: string | null = null;
  priorityIndex?: number | null = null;
  valueType?: string | null = null;
  value?: number | null = null;
  crmKey?: string | null = null;
  crmType?: string | null = null;
  crmSync?: boolean | null = null;
  url?: string | null = null;
  daysOld?: number | null = null;
  status?: string | null = null;
  userValue?: string | null = null;
  topClient?: boolean | null = null;
  daysToExpiration?: number | null = null;
  ticker?: string | null = null;
  productName?: string | null = null;
  contractRecommendationStrengthType: ContractRecommendationStrengthType = ContractRecommendationStrengthType.High;

  constructor(init?: Partial<ContractRecommendationDto>) {
    Object.assign(this, init);
  }
}

export class ContractRecommendationSummaryDto {
  totalCount: number = 0;
  totalValue: number = 0;
  statusBreakdown?: Record<ContractRecommendationStrengthType, number> | null = null;

  constructor(init?: Partial<ContractRecommendationSummaryDto>) {
    Object.assign(this, init);
  }
}

export class ContractTransactionDto {
  id: string = '';
  contractNumber?: string | null = null;
  transactionAmount: number = 0;
  transactionAmountDebitCreditIndicator?: string | null = null;
  loadSetId: string = '';
  createdDate?: Date | null = new Date(0);
  transactionDateProcess?: Date | null = null;
  definitions?: string | null = null;
  contractType?: string | null = null;
  contractRecordId: string = '';
  firmId?: string | null = null;
  cusipNumber?: string | null = null;
  transactionDateEffective?: Date | null = null;

  constructor(init?: Partial<ContractTransactionDto>) {
    Object.assign(this, init);
  }
}

export class ContractValuationDto {
  id: string = '';
  loadSetId: string = '';
  contractNumber?: string | null = null;
  valuationAmount: number = 0;
  contractValuationCodeId: number = 0;
  createUser?: string | null = null;
  createDate?: Date | null = new Date(0);
  updatedUser?: string | null = null;
  updateDate?: Date | null = new Date(0);
  contractRecordId: string = '';
  contractValuationType?: ContractValuationTypeDto | null = null;

  constructor(init?: Partial<ContractValuationDto>) {
    Object.assign(this, init);
  }
}

export class ContractValuationTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ContractValuationTypeDto>) {
    Object.assign(this, init);
  }
}

export class ContractRiskDto {
  proposalRiskScore: number = 0;
  riskScaleId?: string | null = null;
  riskScale?: ContractRiskScaleDto[] | null = null;
  lowRiskTolerance: number = 0;
  highRiskTolerance: number = 0;
  assessedRiskScore: number = 0;
  modelRiskScore: number = 0;
  displayMessage?: string | null = null;

  constructor(init?: Partial<ContractRiskDto>) {
    Object.assign(this, init);
  }
}

export class ContractRiskScaleDto {
  riskLow: number = 0;
  riskHigh: number = 0;
  riskClass?: string | null = null;

  constructor(init?: Partial<ContractRiskScaleDto>) {
    Object.assign(this, init);
  }
}

export class CreditingStrategyDto {
  dataSource: DataSource = DataSource.Ppfa;
  morningstarCreditingStrategy?: MorningstarCreditingStrategyDto | null = null;

  constructor(init?: Partial<CreditingStrategyDto>) {
    Object.assign(this, init);
  }
}

export class CreditingStrategySearchCriteriaDto {
  dataSources?: DataSource[] | null = [];
  cusip?: string | null = null;
  productCode?: string | null = null;
  morningstarPolicyId?: string | null = null;
  insuranceCompanyId?: string | null = null;

  constructor(init?: Partial<CreditingStrategySearchCriteriaDto>) {
    Object.assign(this, init);
  }
}

export class FilterCategoryDto {
  code?: string | null = null;
  values: string[] | null = null;

  constructor(init?: Partial<FilterCategoryDto>) {
    Object.assign(this, init);
  }
}

export class FilterDetailDto {
  id?: number = 0;
  filterCategoryId?: number = 0;
  description?: string | null = null;
  isActive?: boolean | null = null;
  filterValue?: string | null = null;
  filterMinValue?: number | null = null;
  filterMaxValue?: number | null = null;

  constructor(init?: Partial<FilterDetailDto>) {
    Object.assign(this, init);
  }
}

export class FilterDto {
  id: number = 0;
  filterProductTypeId: number = 0;
  code?: string | null = null;
  description?: string | null = null;
  isActive?: boolean | null = null;
  filters?: FilterDetailDto[] | null = null;

  constructor(init?: Partial<FilterDto>) {
    Object.assign(this, init);
  }
}

export class FilterSearchCriteriaDto {
  filterCategoriesToRetrieve?: string[] | null = null;
  filters?: FilterCategoryDto[] | null = [];
  allowableProducts?: AllowableProductDto[] | null = [];

  constructor(init?: Partial<FilterSearchCriteriaDto>) {
    Object.assign(this, init);
  }
}

export class FirmOverlayDto {
  name?: string;
  riderSubaccountGroupKey?: string;
  riskScoreMinimum: number = 0;
  riskScoreMaximum: number = 0;
  overlaySubaccountGroups?: OverlaySubaccountGroupDto[] | null = null;

  constructor(init?: Partial<FirmOverlayDto>) {
    Object.assign(this, init);
  }
}

export class GetProductDetailDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  filters?: FilterCategoryDto[] | null = [];
  morningstarPolicyId?: string | null = null;
  insuranceCompanyId?: string | null = null;
  platformAuthenticationId?: string | null = null;

  constructor(init?: Partial<GetProductDetailDto>) {
    Object.assign(this, init);
  }
}

export class GroupDto {
  groupType: GroupType = GroupType.RiderSection;
  parentContextKey?: string | null = null;
  contextKey?: string | null = null;
  associatedContextKeys?: string[] | null = null;
  childContextKeys?: string[] | null = null;
  sequence: number = 0;
  isTopLevel: boolean = false;
  isDefault: boolean = false;
  data:
    | GroupDataDto
    | GroupOfGroupsDataDto
    | ProgramOptionGroupDataDto
    | RiderSubaccountGroupGroupDataDto
    | RiderSubaccountGroupSubaccountGroupDataDto
    | ProductOverlayGroupData
    | null = null;

  constructor(init?: Partial<GroupDto>) {
    Object.assign(this, init);
  }
}

export class GroupDataDto {
  groupTitle?: string | null = null;

  constructor(init?: Partial<GroupDataDto>) {
    Object.assign(this, init);
  }
}

export class GroupOfGroupsDataDto extends GroupDataDto {
  headers?: string[] | null = null;
  properties?: string[] | null = null;

  constructor(init?: Partial<GroupOfGroupsDataDto>) {
    super(init);
    Object.assign(this, init);
  }
}

export class ProgramOptionGroupDataDto extends GroupDataDto {
  isDca: boolean = false;
  isInitialPremium = false;

  constructor(init?: Partial<ProgramOptionGroupDataDto>) {
    super(init);
    Object.assign(this, init);
  }
}

export class RiderSubaccountGroupGroupDataDto extends GroupDataDto {
  fullName?: string | null = null;
  shortName?: string | null = null;
  allocationMinimumPercent?: number | null = null;
  allocationMaximumPercent?: number | null = null;
  logicFlag?: number | null = null;

  constructor(init?: Partial<RiderSubaccountGroupGroupDataDto>) {
    super(init);
    Object.assign(this, init);
  }
}

export class RiderSubaccountGroupSubaccountGroupDataDto extends GroupDataDto {
  minDepositPct: number = 0;
  maxDepositPct: number = 0;
  properties?: string[] | null = null;

  constructor(init?: Partial<RiderSubaccountGroupSubaccountGroupDataDto>) {
    super(init);
    Object.assign(this, init);
  }
}

export class ProductOverlayGroupData extends GroupDataDto {
  riskScoreMinimumPercent?: number | null = null;
  riskScoreMaximumPercent?: number | null = null;

  constructor(init?: Partial<ProductOverlayGroupData>) {
    super(init);
    Object.assign(this, init);
  }
}

export class MorningstarCreditingStrategyDto {
  morningstarInvestmentVehicleId?: string | null = null;
  name?: string | null = null;
  minimumPremiumBand?: number | null = null;
  underlyingIndex?: string | null = null;
  duration?: string | null = null;
  creditingMethod?: string | null = null;
  interestCap?: number | null = null;
  interestCapMinimum?: number | null = null;
  interestSpread?: number | null = null;
  interestSpreadMaximum?: number | null = null;
  participationRate?: number | null = null;
  participationRateMinimum?: number | null = null;
  triggerRate?: number | null = null;
  triggerThreshold?: number | null = null;
  bufferRate?: number | null = null;
  floorRate?: number | null = null;
  inverseTrigger?: boolean | null = null;
  rateType?: number | null = null;
  rateTypeName?: string | null = null;
  guaranteedPeriod?: number | null = null;
  policyIssueDateMin?: Date | null = null;
  policyIssueDateMax?: Date | null = null;
  rate?: number | null = null;
  bonusRate?: number | null = null;
  bailOutRate?: number | null = null;
  stepRate?: number | null = null;
  bandId?: number | null = null;
  asOfDate?: Date | null = null;
  subaccountKey?: string | null = null;
  dataSource: DataSource = DataSource.Ppfa;
  isFavorite: boolean = false;
  replacementRate?: number | null = null;

  constructor(init?: Partial<MorningstarCreditingStrategyDto>) {
    Object.assign(this, init);
  }
}

export class MorningstarPolicyDto {
  morningstarPolicyId?: string | null = null;
  insuranceCompanyId?: string | null = null;
  name?: string | null = null;
  legalName?: string | null = null;
  inceptionDate?: Date | null = null;
  shortName?: string | null = null;
  annuityType?: string | null = null;
  compensationAge?: number | null = null;
  annuityFeeDescription?: string | null = null;
  annuityFee?: number | null = null;
  administrationFee?: number | null = null;
  mortalityAndExpenseFee?: number | null = null;
  mortalityAndExpenseFeeDescription?: string | null = null;
  minimumInitialPremium?: number | null = null;
  minimumSubsequentPurchase?: number | null = null;
  maximumIssueAge?: number | null = null;
  cusip?: string | null = null;
  productCode?: string | null = null;
  asOfDate?: Date | null = null;
  dataSource: DataSource = DataSource.Ppfa;
  carrier?: CarrierDto | null = null;
  policyFees?: MorningstarPolicyFeeDto[] | null = null;
  subaccounts?: MorningstarSubaccountDto[] | null = null;
  creditingStrategies?: MorningstarCreditingStrategyDto[] | null = null;
  riders?: MorningstarRiderDto[] | null = null;
  descriptions?: MorningstarPolicyDescriptionDto[] | null = null;
  stateAvailability?: StatesCollectionDto | null = null;

  constructor(init?: Partial<MorningstarPolicyDto>) {
    Object.assign(this, init);
  }
}

export class MorningstarPolicyDescriptionDto {
  morningstarDescriptionType: MorningstarDescriptionType = MorningstarDescriptionType.Transfer;
  description?: string | null = null;

  constructor(init?: Partial<MorningstarPolicyDescriptionDto>) {
    Object.assign(this, init);
  }
}

export class MorningstarPolicyFeeDto {
  id: string = '';
  breakpointUnit?: number | null = null;
  lowBreakpoint?: number | null = null;
  highBreakpoint?: number | null = null;
  feesUnit?: number | null = null;
  fees?: number | null = null;
  asOfDate?: Date | null = null;
  description?: string | null = null;

  constructor(init?: Partial<MorningstarPolicyFeeDto>) {
    Object.assign(this, init);
  }
}

export class MorningstarPolicySummaryDto {
  morningstarPolicyId?: string | null = null;
  insuranceCompanyId?: string | null = null;
  name?: string | null = null;
  legalName?: string | null = null;
  inceptionDate?: Date | null = null;
  shortName?: string | null = null;
  annuityType?: string | null = null;
  compensationAge?: number | null = null;
  annuityFeeDescription?: string | null = null;
  annuityFee?: number | null = null;
  administrationFee?: number | null = null;
  mortalityAndExpenseFee?: number | null = null;
  mortalityAndExpenseFeeDescription?: string | null = null;
  minimumInitialPremium?: number | null = null;
  minimumSubsequentPurchase?: number | null = null;
  maximumIssueAge?: number | null = null;
  cusip?: string | null = null;
  productCode?: string | null = null;
  asOfDate?: Date | null = null;
  dataSource: DataSource = DataSource.Ppfa;
  carrier?: CarrierDto | null = null;
  policyFees?: MorningstarPolicyFeeDto[] | null = null;

  constructor(init?: Partial<MorningstarPolicySummaryDto>) {
    Object.assign(this, init);
  }
}

export class MorningstarRiderDto {
  riderId: number = 0;
  name?: string | null = null;
  type?: string | null = null;
  description?: string | null = null;
  annualFeeMin?: number | null = null;
  annualFeeMax?: number | null = null;
  ageMin?: number | null = null;
  ageMax?: number | null = null;
  asOfDate?: Date | null = null;
  dataSource: DataSource = DataSource.Ppfa;

  constructor(init?: Partial<MorningstarRiderDto>) {
    Object.assign(this, init);
  }
}

export class MorningstarSubaccountAssetAllocationDto {
  morningstarInvestmentVehicleId?: string | null = null;
  usstockLong?: number | null = null;
  nonUsstockLong?: number | null = null;
  usbondLong?: number | null = null;
  nonUsbondLong?: number | null = null;
  cashLong?: number | null = null;
  otherLong?: number | null = null;
  usstockShort?: number | null = null;
  nonUsstockShort?: number | null = null;
  usbondShort?: number | null = null;
  nonUsbondShort?: number | null = null;
  cashShort?: number | null = null;
  otherShort?: number | null = null;
  asOfDate?: Date | null = null;
  cusip?: string | null = null;
  productCode?: string | null = null;
  subaccountKey?: string | null = null;

  constructor(init?: Partial<MorningstarSubaccountAssetAllocationDto>) {
    Object.assign(this, init);
  }
}

export class MorningstarSubaccountDto {
  morningstarInvestmentVehicleId?: string | null = null;
  name?: string | null = null;
  legalName?: string | null = null;
  morningstarAssetClassCategory?: string | null = null;
  underlyingFundId?: string | null = null;
  oneMonthPerformance?: number | null = null;
  threeMonthPerformance?: number | null = null;
  sixMonthPerformance?: number | null = null;
  oneYearPerformance?: number | null = null;
  threeYearPerformance?: number | null = null;
  fiveYearPerformance?: number | null = null;
  tenYearPerformance?: number | null = null;
  ytdperformance?: number | null = null;
  sinceInceptionPerformance?: number | null = null;
  managerTenure?: number | null = null;
  subAccountInceptionDate?: Date | null = null;
  turnover?: number | null = null;
  benchmark?: string | null = null;
  sponsorName?: string | null = null;
  returnOnAssets?: number | null = null;
  returnOnEquity?: number | null = null;
  earningsYield?: number | null = null;
  cashFlowYield?: number | null = null;
  unitValue?: number | null = null;
  assetsUnderManagement?: number | null = null;
  morningstarRating?: number | null = null;
  grossExpenseRatio?: number | null = null;
  netExpenseRatio?: number | null = null;
  oneYearHighNav?: number | null = null;
  oneYearLowNav?: number | null = null;
  minimumInitialInvestment?: number | null = null;
  minimumInitialInvestmentUnit?: string | null = null;
  morningstarCategory?: string | null = null;
  asOfDate?: Date | null = null;
  subaccountKey?: string | null = null;
  dataSource: DataSource = DataSource.Ppfa;
  subaccountRisk?: MorningstarSubaccountRiskDto | null = null;
  morningstarSubaccountAssetAllocation?: MorningstarSubaccountAssetAllocationDto | null = null;
  isFavorite: boolean = false;

  constructor(init?: Partial<MorningstarSubaccountDto>) {
    Object.assign(this, init);
  }
}

export class MorningstarSubaccountRiskDto {
  morningstarInvestmentVehicleId?: string | null = null;
  primaryIndexBetaY1?: number | null = null;
  standardDeviationY1?: number | null = null;
  sharpeRatioY1?: number | null = null;
  primaryIndexAlphaY1?: number | null = null;
  primaryIndexRsquaredY1?: number | null = null;
  primaryIndexBetaY3?: number | null = null;
  standardDeviationY3?: number | null = null;
  sharpeRatioY3?: number | null = null;
  primaryIndexAlphaY3?: number | null = null;
  primaryIndexRsquaredY3?: number | null = null;
  primaryIndexBetaY5?: number | null = null;
  standardDeviationY5?: number | null = null;
  sharpeRatioY5?: number | null = null;
  primaryIndexAlphaY5?: number | null = null;
  primaryIndexRsquaredY5?: number | null = null;
  primaryIndexBetaY10?: number | null = null;
  standardDeviationY10?: number | null = null;
  sharpeRatioY10?: number | null = null;
  primaryIndexAlphaY10?: number | null = null;
  primaryIndexRsquaredY10?: number | null = null;
  primaryIndexBetaY15?: number | null = null;
  standardDeviationY15?: number | null = null;
  sharpeRatioY15?: number | null = null;
  primaryIndexAlphaY15?: number | null = null;
  primaryIndexRsquaredY15?: number | null = null;
  primaryIndexBetaY20?: number | null = null;
  standardDeviationY20?: number | null = null;
  sharpeRatioY20?: number | null = null;
  primaryIndexAlphaY20?: number | null = null;
  primaryIndexRsquaredY20?: number | null = null;
  asOfDate?: Date | null = null;
  cusip?: string | null = null;
  productCode?: string | null = null;
  subaccountKey?: string | null = null;

  constructor(init?: Partial<MorningstarSubaccountRiskDto>) {
    Object.assign(this, init);
  }
}

export class OffPlatformProductDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  dtccMemberCode?: string | null = null;
  name?: string | null = null;
  productType?: string | null = null;
  carrierName?: string | null = null;
  carrierCode?: string | null = null;
  morningstarPolicyId?: string | null = null;
  carrierDtccId?: string | null = null;

  constructor(init?: Partial<OffPlatformProductDto>) {
    Object.assign(this, init);
  }
}

export class OverlaySubaccountGroupDto {
  subaccountGroupKey?: string | null = null;
  groupName?: string | null = null;
  allocationMinimumPercent: number = 0;
  allocationMaximumPercent: number = 0;
  sequence: number = 0;
  subaccountKeys?: string[] | null = null;

  constructor(init?: Partial<OverlaySubaccountGroupDto>) {
    Object.assign(this, init);
  }
}

export class ProductDetailDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  name?: string | null = null;
  productTypeCode?: string | null = null;
  productType?: string | null = null;
  isFavorite: boolean = false;
  usesAdvancedIllustration: boolean = false;
  allowableStates?: string[] | null = null;
  carrierGroupCode?: string | null = null;
  naicCode?: string | null = null;
  dtccMemberCode?: string | null = null;
  distributorDtccMemberCode?: string | null = null;
  totalFeeAmt?: number | null = null;
  productFee?: ProductFeeDto | null = null;
  morningstarPolicyId?: string | null = null;
  illustrationType?: IllustrationType | null = null;
  productProperties?: ProductPropertyDto[] | null = null;
  carrier?: CarrierDto | null = null;
  contactInformation?: ContactInformationDto | null = null;
  features?: ProductFeatureDto[] | null = null;
  riders?: ProductDetailRiderDto[] | null = null;
  subaccounts?: SubaccountDto[] | null = null;
  riderRuleGroups?: RiderRuleGroupDto[] | null = null;
  riderConflicts?: RiderConflictDto[] | null = null;
  riderRequisites?: RiderRequisiteDto[] | null = null;
  groups?: GroupDto[] | null = null;
  arrangementTypes?: ArrangementTypeDto[] | null = null;
  productOverlay?: ProductOverlayDto | null = null;
  dataSource: DataSource = DataSource.Ppfa;
  morningstarPolicy?: MorningstarPolicyDto | null = null;
  allowedRelationships?: AllowedRelationshipDto[] | null = null;

  constructor(init?: Partial<ProductDetailDto>) {
    Object.assign(this, init);
  }
}

export class ProductOverlayDto {
  firmOverlays?: FirmOverlayDto[] | null = null;
  required: boolean = false;
  strictnessLevel: ProductOverlayStrictnessLevel = ProductOverlayStrictnessLevel.Strict;

  constructor(init?: Partial<ProductOverlayDto>) {
    Object.assign(this, init);
  }
}

export class ProductDetailRiderDto {
  riderKey?: string | null = null;
  name?: string | null = null;
  description?: string | null = null;
  defaultInd: number = 0;
  isActive: boolean = false;
  totalFeeAmt?: number | null = null;
  totalFeePct?: number | null = null;
  advisorFeeCapPct: number = 0;
  sequence: number = 0;
  featureMappingCode?: number | null = null;
  arrangementType?: ArrangementTypeDto | null = null;
  arrangementSubType?: ArrangementSubTypeDto | null = null;
  maxNumSourceInvestments?: number | null = null;
  maxNumDestinationInvestments?: number | null = null;
  minNumSourceInvestments?: number | null = null;
  minNumDestinationInvestments?: number | null = null;
  riderSubType?: RiderSubType | null = null;
  riderType: RiderType = RiderType.Unknown;
  featureProductKey?: string | null = null;
  featureOptProductKey?: string | null = null;
  featureOptProductName?: string | null = null;
  benefitType?: BenefitTypeDto | null = null;
  riderRuleGroupKey?: string | null = null;
  riderSubaccountGroupKey?: string | null = null;
  excludedSubaccountKeys: string[] | null = null;
  destinationSubaccountKeys?: string[] | null = null;
  sourceSubaccountKeys?: string[] | null = null;
  features?: RiderFeatureDto[] | null = null;
  minBeneficiaryAge?: number | null = null;
  maxBeneficiaryAge?: number | null = null;
  allowedRelationships?: AllowedRelationshipDto[] | null = null;
  surrenderChargeSchedule?: SurrenderChargeScheduleDto[] | null = null;
  destTransferAmtType?: TransferAmtTypeDto | null = null;
  sourceTransferAmtType?: TransferAmtTypeDto | null = null;
  dataSource: DataSource = DataSource.Ppfa;

  constructor(init?: Partial<ProductDetailRiderDto>) {
    Object.assign(this, init);
  }
}

export class ProductFeatureDto {
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ProductFeatureDto>) {
    Object.assign(this, init);
  }
}

export class ProductFeeDto {
  policyFeeAmt?: number | null = null;
  policyFeePct?: number | null = null;
  mefeeAmt?: number | null = null;
  mefeePct?: number | null = null;
  administrationFeeAmt?: number | null = null;
  administrationFeePct?: number | null = null;
  otherFeeAmt?: number | null = null;
  otherFeePct?: number | null = null;

  constructor(init?: Partial<ProductFeeDto>) {
    Object.assign(this, init);
  }
}

export class ProductFilterKeyDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  filterId?: number | null = null;
  filterCategoryId?: number | null = null;

  constructor(init?: Partial<ProductFilterKeyDto>) {
    Object.assign(this, init);
  }
}

export class ProductPropertyDto {
  propertyName?: string | null = null;
  propertyValue?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<ProductPropertyDto>) {
    Object.assign(this, init);
  }
}

export class ProductSummaryDto {
  name?: string | null = null;
  cusip?: string | null = null;
  productCode?: string | null = null;
  productType?: string | null = null;
  productTypeCode?: string | null = null;
  allowableStates?: string[] | null = null;
  carrierGroupCode?: string | null;
  naicCode?: string | null;
  dtccMemberCode?: string | null;
  distributorDtccMemberCode?: string | null;
  totalFeeAmt?: number | null;
  morningstarPolicyId?: string | null;
  illustrationType?: IllustrationType | null;
  properties?: ProductPropertyDto[] | null = null;
  features?: ProductFeatureDto[] | null = null;
  carrier?: CarrierDto | null = null;
  dataSource: DataSource = DataSource.Ppfa;
  morningstarPolicy?: MorningstarPolicySummaryDto | null = null;
  isFavorite: boolean = false;
  allowedRelationships: AllowedRelationshipDto[] | null = null;

  constructor(init?: Partial<ProductSummaryDto>) {
    Object.assign(this, init);
  }
}

export class ProductSummarySearchCriteriaDto {
  dataSources?: DataSource[] | null = [];
  filters?: FilterCategoryDto[] | null = [];
  allowableProducts?: AllowableProductDto[] | null = [];

  constructor(init?: Partial<ProductSummarySearchCriteriaDto>) {
    Object.assign(this, init);
  }
}

export class ProposalSummaryDto {
  totalCount: number = 0;
  statusBreakdown?: Record<ProposalStatusType, number> | null = null;

  constructor(init?: Partial<ProposalSummaryDto>) {
    Object.assign(this, init);
  }
}

export class RiderConflictDto {
  sourceKey?: string | null = null;
  sourceKeyContextType: ContextType = ContextType.Cusip;
  conflictKey?: string | null = null;
  conflictKeyContextType: ContextType = ContextType.Cusip;

  constructor(init?: Partial<RiderConflictDto>) {
    Object.assign(this, init);
  }
}

export class RiderDto {
  riderKey?: string | null = null;
  name?: string | null = null;
  description?: string | null = null;
  benefitType?: BenefitTypeDto | null = null;
  riderType: RiderType = RiderType.Unknown;
  riderTypeDescription?: string | null = null;
  riderSubType?: RiderSubType | null = null;
  riderSubTypeDescription?: string | null = null;
  featureProductKey?: string | null = null;
  featureCode?: string | null = null;
  featureMappingCode?: number | null = null;
  featureProductCode?: string | null = null;
  featureProductName?: string | null = null;
  featureOptProductKey?: string | null = null;
  featureOptProductName?: string | null = null;
  isActive: boolean = false;
  defaultInd: number = 0;
  sequence: number = 0;
  arrangementType?: ArrangementTypeDto | null = null;
  arrangementSubType?: ArrangementSubTypeDto | null = null;
  selectionRuleType?: SelectionRuleType | null = null;
  selectionRuleDescription?: string | null = null;
  riderFee?: RiderFeeDto | null = null;
  totalFeeAmt?: number | null = null;
  totalFeePct?: number | null = null;
  advisorFeeCapPct: number = 0;
  allowableStates?: string[] | null = null;
  features?: RiderFeatureDto[] | null = null;
  surrenderChargeSchedule?: SurrenderChargeScheduleDto[] | null = null;
  minBeneficiaryAge?: number | null = null;
  maxBeneficiaryAge?: number | null = null;
  destTransferAmtType?: TransferAmtTypeDto | null = null;
  sourceTransferAmtType?: TransferAmtTypeDto | null = null;
  dataSource: DataSource = DataSource.Ppfa;
  morningstarRider?: MorningstarRiderDto | null = null;

  constructor(init?: Partial<RiderDto>) {
    Object.assign(this, init);
  }
}

export class RiderFeatureDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  riderKey?: string | null = null;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<RiderFeatureDto>) {
    Object.assign(this, init);
  }
}

export class RiderFeeDto {
  feeName?: string | null = null;
  feeDescription?: string | null = null;
  feeType: FeeType = FeeType.Unknown;
  feeTypeDescription?: string | null = null;
  feeMode?: number | null = null;
  feeAmount?: number | null = null;
  feePercent?: number | null = null;

  constructor(init?: Partial<RiderFeeDto>) {
    Object.assign(this, init);
  }
}

export class RiderFilterKeyDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  riderKey?: string | null = null;
  filterId: number = 0;
  filterCategoryId: number = 0;

  constructor(init?: Partial<RiderFilterKeyDto>) {
    Object.assign(this, init);
  }
}

export class RiderRequisiteDto {
  sourceKey?: string | null = null;
  sourceKeyContextType: ContextType = ContextType.Cusip;
  requisiteKey?: string | null = null;
  requisiteKeyContextType: ContextType = ContextType.Cusip;

  constructor(init?: Partial<RiderRequisiteDto>) {
    Object.assign(this, init);
  }
}

export class RiderRuleGroupDto {
  riderRuleGroupKey?: string | null = null;
  maxAllowableRiderSelections: number = 0;
  selectionRuleType: SelectionRuleType = SelectionRuleType.Unknown;
  featureProductKey?: string | null = null;
  sequence: number = 0;
  name?: string | null = null;

  constructor(init?: Partial<RiderRuleGroupDto>) {
    Object.assign(this, init);
  }
}

export class RiderSearchCriteriaDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  filters?: FilterCategoryDto[] | null = [];
  dataSources?: DataSource[] | null = [];
  morningstarPolicyId?: string | null = null;
  insuranceCompanyId?: string | null = null;

  constructor(init?: Partial<RiderSearchCriteriaDto>) {
    Object.assign(this, init);
  }
}

export class RiskCalculationGetDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  dtccMemberCode?: string | null = null;
  platformUserId?: string | null = null;
  platformAuthenticationId?: string | null = null;
  platformRiskScaleId?: string | null = null;
  strategy: RiskCalculationStrategy = RiskCalculationStrategy.Fidx;
  riders?: string[] | null = [];
  subaccountAllocations?: { [key: string]: number } | null = {};

  constructor(init?: Partial<RiskCalculationGetDto>) {
    Object.assign(this, init);
  }
}

export class RiskCalculationResultBase {
  strategy: RiskCalculationStrategy = RiskCalculationStrategy.Fidx;
  result?: string;
  score: number = 0;
  warningMessages: string[] = [];

  constructor(init?: Partial<RiskCalculationResultBase>) {
    Object.assign(this, init);
  }
}

export class StatesCollectionDto {
  states?: StateDto[] | null = null;
  areStatesExclusive: boolean = false;

  constructor(init?: Partial<StatesCollectionDto>) {
    Object.assign(this, init);
  }
}

export class StateDto {
  code?: string | null = null;
  name?: string | null = null;

  constructor(init?: Partial<StateDto>) {
    Object.assign(this, init);
  }
}

export class SubaccountDto {
  subaccountKey?: string | null = null;
  name?: string | null = null;
  subaccountCusip?: string | null = null;
  assetClass?: string | null = null;
  rateType?: string | null = null;
  morningStarInvestmentVehicleId?: string | null = null;
  investProductKey?: string | null = null;
  carrierName?: string | null = null;
  shortName?: string | null = null;
  carrierCode?: string | null = null;
  totalFeeAmt?: number | null = null;
  totalFeePct?: number | null = null;
  sequence: number = 0;
  subaccountProductCode?: string | null = null;
  subaccountInsuranceExpense?: number | null = null;
  minPremiumAmount?: number | null = null;
  minIssueAge?: number | null = null;
  maxIssueAge?: number | null = null;
  limitType?: string | null = null;
  platformRiskDescription?: string | null = null;
  riskDescription?: string | null = null;
  calculationRiskType: RiskCalculationType = RiskCalculationType.Default;
  dataSource: DataSource = DataSource.Ppfa;
  morningstarSubaccount?: MorningstarSubaccountDto | null = null;
  isFavorite: boolean = false;

  constructor(init?: Partial<SubaccountDto>) {
    Object.assign(this, init);
  }
}

export class SubaccountFilterKeyDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  subaccountKey?: string | null = null;
  filterId: number = 0;
  filterCategoryId: number = 0;

  constructor(init?: Partial<SubaccountFilterKeyDto>) {
    Object.assign(this, init);
  }
}

export class SubaccountRiskDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  subaccountKey?: string | null = null;
  riskDescription?: string | null = null;

  constructor(init?: Partial<SubaccountRiskDto>) {
    Object.assign(this, init);
  }
}

export class SubaccountSearchCriteriaDto {
  cusip?: string | null = null;
  productCode?: string | null = null;
  filters?: FilterCategoryDto[] | null = [];
  dataSources?: DataSource[] | null = [];
  morningstarPolicyId?: string | null = null;
  insuranceCompanyId?: string | null = null;

  constructor(init?: Partial<SubaccountSearchCriteriaDto>) {
    Object.assign(this, init);
  }
}

export class SurrenderChargeScheduleDto {
  riderKey?: string | null = null;
  surrenderChargeType?: SurrenderChargeType | null = null;
  chargeBasedOn?: ChargeBasedOn | null = null;
  duration: number = 0;
  durQualifier?: number | null = null;
  percentage?: number | null = null;
  rateBasedOnDescription?: string | null = null;
  surrenderChargeTypeDescription?: string | null = null;

  constructor(init?: Partial<SurrenderChargeScheduleDto>) {
    Object.assign(this, init);
  }
}

export class TransferAmtTypeDto {
  id: number = 0;
  code?: string | null = null;
  description?: string | null = null;

  constructor(init?: Partial<TransferAmtTypeDto>) {
    Object.assign(this, init);
  }
}
